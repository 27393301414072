#actions {
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;

  #back {
    pointer-events: auto;
    position: absolute;
    top: 40px;
    left: 40px;
    padding-right: 7px;
  }

  #logo {
    pointer-events: auto;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 0 15px rgba(0,0,0,0.3);
    background-color: white;
    padding: 15px 30px;
  }

  #children {
    pointer-events: auto;
    position: absolute;
    top: 40px;
    right: 40px;
    list-style: none;

    .child {
      display: inline-block;

      +.child {
        margin-left: 5px;
      }

      .btn {
        border: solid 4px white;

        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }

      &.active {
        .btn {
          border-color: $brand-primary;
        }
      }
    }
  }

  #sound {
    pointer-events: auto;
    position: absolute;
    bottom: 40px;
    right: 40px;

    .active, .mute {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: block;
      opacity: 1;
    }

    .mute {
      opacity: 0;
    }

    &.muted {
      .active {
        opacity: 0;
      }

      .mute {
        opacity: 1;
      }
    }
  }
}
