@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes zoom {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes zoomInScene{
  0% {
    transform: scale(0.6);
    opacity: 0.6;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeInScene{
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOutScene {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translate(0, -40%);
  }

  100% {
    opacity: 0;
    transform: translate(-20%, -40%);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: none;
  }

  100% {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: none;
  }

  100% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes scaleUpMoveDown {
  0%{
    transform: scale(1);
  }

  100%{
    transform: scale(1.2);
    bottom: -8%;
  }
}

@keyframes scaleUpMoveDown2 {
  0%{
    width: 840px;
    height: 355px;
    left: 50%;
    bottom: 24%;
    transform: translateY(-53%);
  }

  100%{
    width: calc(83% + 5px);
    left: calc(9% - 12px);
    bottom: calc(24% - 1px);
    transform: translateY(0);
    height: 79vh;
  }
}

@keyframes moveLeftFromRight {
  0%{
    right: 15%;
    transform: translate(0, -50%) scale(1);
  }

  100%{
    right: 45%;
    transform: translate(20%, -60%) scale(1.5);
  }
}

@keyframes moveLeftFromRight2 {
  0%{
    right: 15%;
    transform: translate(0, -50%) scale(1);
  }

  100%{
    right: 45%;
    transform: translate(20%, -60%) scale(1.5);
  }
}

@keyframes scaleMoveDown {
  0%{
    transform: scale(1) translateX(0);
    bottom: 0;
  }

  100%{
    transform: scale(1.5) translateX(-20%);
    bottom: -30%;
  }
}


@keyframes scaleMoveDown2 {
  0%{
    transform: scale(1) translateX(0);
    bottom: 0;
  }

  100%{
    transform: scale(1.5) translateX(-20%);
    bottom: -100%;
  }
}

@keyframes scaleOutUp {
  0%{
    transform: scale(1) translate(0,0);
    opacity: 1;
  }

  100%{
    transform: scale(2) translate(-40%,-100%);
    opacity: 0;
  }
}

@keyframes scaleOutUpRight {
  0%{
    transform: scale(1) translate(0,0);
    opacity: 1;
  }

  100%{
    transform: scale(2) translate(100%,50%);
    opacity: 0;
  }
}

@keyframes scaleOutRight {
  0%{
    transform: scale(1) translate(0,0);
    opacity: 1;
  }

  100%{
    transform: scale(2) translate(100%,0);
    opacity: 0;
  }
}

@keyframes scaleOut {
  0%{
    transform: scale(1) translate(0,0);
    opacity: 1;
  }

  100%{
    transform: scale(2) translate(-40%,100%);
    opacity: 0;
  }
}

@keyframes popIn {
  0%{
    transform: scale(0);
    opacity: 1;
  }

  80%{
    transform: scale(1.2);
  }

  100%{
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes jump {
  0% {
    top: 0;
    animation-timing-function: ease-in;
  }
  50% {
    top: -140px;
    animation-timing-function: ease-out;
  }
  55% {
    top: -160px;
    animation-timing-function: ease-in;
  }
  65% {
    top: -120px;
    animation-timing-function: ease-out;
  }
  90% {
    top: 20px;
    animation-timing-function: ease-in;
  }
  95% {
    height: calc(100% - 20px);
    animation-timing-function: ease-out;
  }
  100% {
    top: 0;
    height: 100%;
    animation-timing-function: ease-in;
  }
}

@keyframes shrink {
  0% {
    bottom: -20px;
    margin-left: -30px;
    width: 60px;
    height: 75px;
    background: rgba(20, 20, 20, .1);
    box-shadow: 0px 0 20px 35px rgba(20,20,20,.1);
    border-radius: 30px / 40px;
    animation-timing-function: ease-in;
  }
  50% {
    bottom: 0px;
    margin-left: -10px;
    width: 20px;
    height: 5px;
    background: rgba(20, 20, 20, .3);
    box-shadow: 0px 0 20px 35px rgba(20,20,20,.3);
    border-radius: 20px / 20px;
    animation-timing-function: ease-out;
  }
  100% {
    bottom: -20px;
    margin-left: -30px;
    width: 60px;
    height: 75px;
    background: rgba(20, 20, 20, .1);
    box-shadow: 0px 0 20px 35px rgba(20,20,20,.1);
    border-radius: 30px / 40px;
    animation-timing-function: ease-in;
  }
}

@keyframes swing-in-top-fwd {
  0% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1;
  }
}


@keyframes wobble-ver-left {
  0%,
  70%,
  100% {
    transform: translateY(0) rotate(0);
    transform-origin: 50% 50%;
  }
  75% {
    transform: translateY(0) rotate(-4deg);
  }
  80% {
    transform: translateY(5px) rotate(4deg);
  }
  85% {
    transform: translateY(0) rotate(-4deg);
  }
  90% {
    transform: translateY(5px) rotate(4deg);
  }
  95% {
    transform: translateY(0) rotate(-4deg);
  }
}

@keyframes anim-open {
  0% { opacity: 0; -webkit-transform: scale3d(0.6, 0.6, 1); transform: scale3d(0.6, 0.6, 1); }
  100% { opacity: 1; -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1) }
}

@keyframes anim-close {
  0% { opacity: 1; }
  100% { opacity: 0; -webkit-transform: scale3d(0.6, 0.6, 1); transform: scale3d(0.6, 0.6, 1); }
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes attention {
  0%,
  50%,
  100% {
    transform: scale(1);
  }
  25%,
  75% {
    transform: scale(1.2);
  }
}

@keyframes scale-up {
  0%,
  100% {
    transform: scale(1);
  }
  15%,
  85% {
    transform: scale(1.3);
  }
}
