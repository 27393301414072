#frame {
  width: 100%;
  height: 100%;
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  z-index: 4;
  transition: filter 0.3s ease-in-out;

  &.blurred {
    filter: blur(5px);
  }

  #tree {
    position: absolute;
    left: 0;
    top: 0;
    background-image: url('../images/boom.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 1000px;
    height: 650px;
  }

  #tree2 {
    position: absolute;
    right: 0;
    top: 0;
    background-image: url('../images/tree.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 599px;
    height: 1080px;
  }

  #tree3 {
    position: absolute;
    right: -150px;
    top: 50px;
    background-image: url('../images/tree-night.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 792px;
    height: 1091px;
  }

  #woezel, #pip {
    position: absolute;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: contain;
    height: 430px;
    width: 260px;
    transition: filter 0.3s ease-in-out;

    &.blurred {
      filter: blur(5px);
    }
  }

  #woezel {
    background-image: url('../images/woezel.png');
  }

  #pip {
    right: auto;
    left: 0;
    background-image: url('../images/pip.png');
  }

  &.preloading {
    #woezel, #pip {
      height: 653px;
      width: 419px;
    }

    #woezel {
      left: 0;
      right: auto;
      background-image: url('../images/woezel.gif');
    }

    #pip {
      right: 0;
      left: auto;
      height: 518px;
      background-image: url('../images/pip.gif');
    }
  }
}