@import url("https://codepen.io/MacEvelly/pen/iJGCw.css");

.holder{
  width:700px;
}
.held{
  position:relative;
  overflow:hidden;
}
.button{
  width:150px;
  padding:10px;
}

#regenboog .swatchHolder {
  position: absolute;
  bottom: 0px;
  margin: auto;
  left: 0px;
  right: 0px;
  list-style-type: none;
  text-align: center;
  display: inline-block;
  padding: 15px;
  width: 260px;
  border-radius: 20px 20px 0px 0px;
  color: black;

  .colorHolder {
    width: 100%;
    line-height: 100%;
    content: 'Chosen Color';
    padding: 10px 0px;
    margin: 0px auto 15px auto;
    cursor: pointer;
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  }

  li:not(.colorHolder) {
    height: 25px;
    width: 25px;
    margin: 2px;
    display: inline-block;
    cursor: pointer;
    border-radius: 5px;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
  }

  svg {
    width: 100%;
    display: inline-block;

    path {
      cursor: pointer;
    }
  }
}