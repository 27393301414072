html, body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

main#main {
  height: 100%;
}

@mixin cursor($type:"hand") {
  cursor: url('../images/cursors/cursor-#{$type}.png'), pointer !important;
}

body {
  &.grapes {
    @include cursor('grapes');
  }
}

.screen-background {
  width: 100%;
  height: 100%;
  background-color: #b7e9f6;
  position: fixed;
  z-index: -1;
}

#texture {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../images/texture-v3.png');
  pointer-events: none;
  z-index: 101;
}
