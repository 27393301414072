#transition {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;

  #cloud-right, #cloud-left {
    width: 100%;
    height: 100%;
    background: url('../images/struik_links.png') no-repeat center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  #cloud-right {
    left: auto;
    right: 0;
    background-image: url('../images/struik_rechts.png');
    z-index: 1;
  }

  .scaleFromRight {

  }

  .scaleFromLeft {

  }
}

#loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: none;
  z-index: 11;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    margin-left: -50px;
    margin-top: -50px;
    text-align: center;
    line-height: 100px;
    font-size: 50px;
  }
}

.is-loading {
  #loading {
    display: block;

    .spinner {
      animation: fadeIn 1s ease-in-out;
    }
  }
}

.m-scene {
  /** Basic styles for an animated element */
  .scene_element {
    animation-duration: 0.5s;
    transition-timing-function: ease-in;
    animation-fill-mode: forwards;
    animation-delay: 0s;

    &.scene_element--fadeoutright {
      animation-name: fadeOutRight;
    }

    &.scene_element--fadeoutleft {
      animation-name: fadeOutLeft;
    }

    &.scene_element--fadeoutleft2 {
      animation-delay: 0.5s;
      animation-name: fadeOutLeft;
    }

    &.scene_element--fade {
      animation-name: fadeOutScene;
      animation-delay: 1.5s;
      animation-duration: 0.3s;
    }

    &.scene_element--zoom,
    &.scene_element--scaleInRight {
      animation: none;
    }

    &.scene_element--fadeOut {
      animation-name: fadeOut;
      animation-delay: 0.5s;
      animation-duration: 1s;
    }

    &.scene_element--scaleOutUpRight {
      animation-name: scaleOutUpRight;
      animation-delay: 0.5s;
      animation-duration: 1s;
    }

    &.scene_element--scaleOutRight {
      animation-name: scaleOutRight;
      animation-delay: 0.5s;
      animation-duration: 1s;
    }

    &.scene_element--scaleOut {
      animation-name: scaleOut;
      animation-delay: 0.5s;
      animation-duration: 1s;
    }

    &.scene_element--scaleOutUp {
      animation-name: scaleOutUp;
      animation-delay: 0.5s;
      animation-duration: 0.5s;
    }

    &.scene_element--scaleUpMoveDown {
      animation-name: scaleUpMoveDown;
      animation-delay: 0.5s;
      animation-duration: 0.5s;
    }

    &.scene_element--scaleUpMoveDown2 {
      animation-name: scaleUpMoveDown2;
      animation-delay: 0.5s;
      animation-duration: 0.5s;
    }

    &.scene_element--moveLeftFromRight {
      animation-name: moveLeftFromRight;
      animation-delay: 0.5s;
      animation-duration: 0.5s;
    }

    &.scene_element--moveLeftFromRight2 {
      animation-name: moveLeftFromRight2;
      animation-delay: 0.5s;
      animation-duration: 0.5s;
    }

    &.scene_element--scaleMoveDown {
      animation-name: scaleMoveDown;
      animation-delay: 0.5s;
      animation-duration: 0.5s;
    }

    &.scene_element--scaleMoveDown2 {
      animation-name: scaleMoveDown2;
      animation-delay: 0.5s;
      animation-duration: 0.5s;
    }
  }

  #screen-container {
    .navigation {
      .navigation-container {
        #foreground, .navigation-element {
          filter: blur(0);
        }

        .navigation-element-2 {
          a {
            display: none;
          }
        }
      }
    }
  }
}

body:not(.is-exiting) {
  .m-scene {
    /** Basic styles for an animated element */
    .scene_element {
      /** An element that fades in */
      &.scene_element--fadein {
        animation-name: fadeIn;
      }

      /** An element that fades in and slides up */
      &.scene_element--fadeinup {
        animation-name: fadeInUp;
      }

      &.scene_element--fade {
        animation-name: fadeInScene;
        animation-delay: 0s;
        animation-duration: 0.3s;
      }

      &.scene_element--zoom {
        animation-name: zoomInScene;
        animation-delay: 0s;
        animation-duration: 0.3s;
      }

      &.scene_element--scaleOutUp {
        animation-name: fadeInLeft;
        animation-delay: 0s;
        animation-duration: 0.3s;
      }

      &.scene_element--scaleInRight,
      &.scene_element--scaleOutRight {
        animation-name: fadeInRight;
        animation-delay: 0s;
        animation-duration: 0.3s;
      }

      &.scene_element--fadeinright {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
        animation-name: fadeInRight;
        animation-delay: 1s;
      }

      &.scene_element--fadeinleft {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
        animation-name: fadeInLeft;
        animation-delay: 1s;
      }

      &.scene_element--fadeinleft2 {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
        animation-name: fadeInLeft;
        animation-delay: 0.5s;
      }

      &.scene_element--scaleOut,
      &.scene_element--fadeOut,
      &.scene_element--fadeoutleft2,
      &.scene_element--scaleUpMoveDown,
      &.scene_element--scaleUpMoveDown2,
      &.scene_element--moveLeftFromRight,
      &.scene_element--moveLeftFromRight2,
      &.scene_element--scaleMoveDown,
      &.scene_element--scaleMoveDown2,
      &.scene_element--scaleOutUpRight {
        animation: none;
      }
    }
  }

  #screen-container {
    .navigation {
      .navigation-container {
        #foreground, .navigation-element {
          filter: blur(2px);
        }

        .navigation-element-2 {
          a {
            display: block;
          }
        }
      }
    }
  }
}
