#loading-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.play img {
  animation: zoom 1.5s forwards;
}

.darker {
  width: 100%;
  height: 100%;
  background: white;
  position: fixed;
  z-index: 1;
  pointer-events: none;
  opacity: 0;

  &.active {
    animation: fadeIn 1.5s forwards;
  }
}

.non-clickable {
  pointer-events: none;
}