.btn  {
  text-align: center;
  border: none;
  outline: none !important;
  transition: all 0.3s ease-in-out;
  @include cursor();

  &.btn-default {
    background-color: white;
    color: $brand-primary;
    box-shadow: 0 0 15px rgba(0,0,0,0.3);

    &:hover {
      background-color: $brand-primary;
      color: white;
    }
  }

  &.btn-round {
    display: block;
    border-radius: 50%;
    overflow: hidden;
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 45px;
    padding: 0;
  }
}