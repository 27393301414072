.screen {
  width: 100%;
  height: 100%;
  position: relative;
  transition: filter 0.3s ease-in-out;
  overflow: hidden;

  &.blurred {
    filter: blur(5px);
  }

  #biebtree {
    position: absolute;
    right: 0;
    top: -359px;
    background-image: url('../images/biebboom.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 768px;
    height: 1295px;
    pointer-events: none;
    transition: filter 0.3s ease-in-out;

    &.blurred {
      filter: blur(5px);
    }
  }

  #screen-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;

    .background, .navigation, .view {
      top: auto !important;
      bottom: 0 !important;
      position: absolute !important;
      //width: 150%;
      width: 100%;
      //margin-left: -25%;
    }

    .background {
      bottom: auto !important;
      top: 0 !important;
      height: 100%;
      background-color: #acdb15;
      background-image: url('../images/1-bg.png');
      background-position: left 0%;
      background-repeat: repeat-x;
    }

    .navigation {
      height: 44%;
      bottom: 20% !important;
      position: relative;
      z-index: 0;

      .navigation-container {
        position: relative;
        height: 100%;
        pointer-events: none;

        #foreground {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: url('../images/2-grass.png');
          background-position: center bottom;
          background-size: auto 100%;
          background-repeat: no-repeat;
          transition: all 0.3s ease-in-out;
        }

        .navigation-element {
          pointer-events: auto;
          display: block;
          position: absolute;
          left: 10%;
          top: 0;
          transform: translateY(-40%);
          backface-visibility: hidden;
          transition: all 0.3s ease-in-out;
          z-index: -1;

          &.active {
            filter: blur(0);
          }

          a {
            position: absolute;
            left: 15%;
            width: 280px;
            height: 280px;
            display: block;
            @include cursor();
          }
        }

        .navigation-element-2 {
          left: auto;
          right: 15%;
          transform: translate(0, -50%);

          a {
            left: 0;
            width: 295px;
            height: 300px;
          }
        }

        &.active {
          #foreground {
            filter: blur(0);
          }
        }
      }
    }

    .navigation-container, .view-container {
      width: 100vw;
      margin: 0 auto;
    }

    .view {
      height: 81%;
      pointer-events: none;
      background: url('../images/scene-1.png');
      background-position: center bottom;
      background-size: 100% auto;
      background-repeat: no-repeat;
      transition: filter 0.3s ease-in-out;
      z-index: 1;

      &.blurred {
        filter: blur(3px);
      }

      &:after {
        content: '';
        position: absolute;
        bottom: -25%;
        left: 0;
        height: 25%;
        width: 100%;
        background: inherit;
      }
    }

    .hidden-element {
      pointer-events: auto;
      opacity: 0;
      transform: scale(0);
      transition: all 0.3s ease-in-out;

      &.dragging {
        background: none !important;
      }

      img {
        display: block;
        transition: all 0.3s ease-in-out;
      }

      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          animation: popIn 0.3s forwards;
          animation-delay: $i * .35s;
        }
      }

      &#bal {
        position: absolute;
        bottom: 60px;
        left: 30%;
        width: 157px;
        height: 151px;

        #ball {
          position: absolute;
          top: 0;
          z-index: 11;
          @include cursor();

          img {
            height: 100%;
          }
        }

        #ballShadow {
          position: absolute;
          left: 50%;
          bottom: -20px;
          z-index: 10;
          margin-left: -30px;
          width: 60px;
          height: 75px;
          background: rgba(20, 20, 20, .1);
          box-shadow: 0px 0 20px 35px rgba(20,20,20,.1);
          border-radius: 30px / 40px;
          transform: scaleY(.3);
        }

        &.jump {
          #ball {
            animation: jump 0.7s forwards;
          }

          #ballShadow {
            animation: shrink 0.7s forwards;
          }
        }
      }

      $bg-color: rgba(250,250,250,0.75);

      &#boek {
        position: absolute;
        bottom: 90px;
        left: 15%;
        @include cursor();

        &:after {
          content: "";
          width: 200px;
          height: 80px;
          position: absolute;
          bottom: 25px;
          left: 28px;
          background: $bg-color;
          box-shadow: 0px 0px 94px 50px $bg-color;
          z-index: -1;
          border-radius: 25px 50px;
          opacity: 0;
          transition: opacity 0.5s;
        }

        &.attention {

          img {
            animation: scale-up 1.2s both;
            animation-delay: 0.2s;
          }

          &:after {
            opacity: 1;
          }
        }
      }

      &#boeken {
        position: absolute;
        bottom: 125px;
        left: 70%;
        @include cursor();

        &:after {
          content: "";
          width: 200px;
          height: 120px;
          position: absolute;
          bottom: 25px;
          left: 28px;
          background: $bg-color;
          box-shadow: 0px 0px 94px 50px $bg-color;
          z-index: -1;
          border-radius: 25px 50px;
          opacity: 0;
          transition: opacity 0.5s;
        }

        &.attention {

          img {
            animation: scale-up 1.2s both;
            animation-delay: 1.1s;
          }

          &:after {
            opacity: 1;
          }
        }
      }
    }
  }
}

#screen2 {
  #screen-container {
    .view {
      background: none;
      z-index: 0;

      .grass {
        position: absolute;
        bottom: -53%;
        left: calc(-46% - 4px);
        transform: scale(1) translateX(-20%);
        width: 200%;
        height: 100%;
        background-image: url('../images/2-grass.png');
        background-position: center bottom;
        background-size: auto 100%;
        background-repeat: repeat-x;
        z-index: -1;
      }

      #rainbow {
        position: absolute;
        bottom: 23%;
        left: calc(36% + 8px);
        z-index: -2;
        pointer-events: auto;

        svg {
          width: 532px;
          height: 540px;
        }
      }

      #hidden-elements {
        position: absolute;
        bottom: 5%;
        width: 100vw;
        text-align: center;

        .hidden-element {
          display: inline-block;
          width: 200px;
          height: 200px;
          transition: width 0.2s, height 0.2s, margin 0.2s;
          @include cursor();

          &#color-red {
            background: url('../images/aardbei.svg') no-repeat center;
          }

          &#color-yellow {
            background: url('../images/banaan.svg') no-repeat center;
          }

          &#color-green {
            background: url('../images/appel.svg') no-repeat center;
          }

          &#color-blue {
            background: url('../images/druif.svg') no-repeat center;
          }

          &#color-purple {
            background: url('../images/aubergine.svg') no-repeat center;
          }

          &.attention {
            width: 250px;
            height: 250px;
            margin: -25px;
          }
        }
      }
    }

    .navigation {
      .navigation-element-2 {
        background: url('../images/perenboom.png');
        background-position: left bottom;
        background-size: 100% auto;
        background-repeat: no-repeat;
        width: 840px;
        height: 355px;
        right: auto;
        left: 50%;
        top: auto;
        bottom: 24%;
        transform: translateY(-53%);

        a {
          width: 260px;
          height: 300px;
          left: 35%;
        }
      }
    }
  }
}

#screen3 {
  #screen-container {
    .view {
      background: url('../images/perenboom.png');
      background-position: left bottom;
      background-size: 100% auto;
      background-repeat: no-repeat;
    }

    #bag {
      background: url('../images/rugzak.png');
      background-size: contain;
      background-repeat: no-repeat;
      width: 250px;
      height: 300px;
      position: absolute;
      bottom: -100%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      transition: bottom 0.3s ease-in-out;

      &.active {
        bottom: 50px;
      }
    }

    #hidden-elements {
      position: absolute;
      bottom: 66%;
      width: 100vw;
      text-align: center;
      z-index: 3;

      .hidden-element {
        display: inline-block;
        width: 200px;
        height: 200px;
        background-size: contain !important;
        transition: width 0.2s, height 0.2s, margin 0.2s;
        @include cursor();

        @for $i from 1 through 5 {
          &:nth-child(#{$i}) {
            animation: popIn 0.3s forwards;
            animation-delay: $i * .35 + 7s;
          }
        }

        &.dragged {
          display: none !important;
        }

        &#item-tandenborstel {
          background: url('../images/tandenborstel.png') no-repeat center;
          width: 100px;

          &.attention {
            width: 150px;
            height: 250px;
            margin: -25px;
          }
        }

        &#item-tandpasta {
          background: url('../images/tandpasta.png') no-repeat center;
          width: 100px;

          &.attention {
            width: 150px;
            height: 250px;
            margin: -25px;
          }
        }

        &#item-kussen {
          background: url('../images/kussen.png') no-repeat center;

          &.attention {
            width: 250px;
            height: 250px;
            margin: -25px;
          }
        }

        &#item-teddybear {
          background: url('../images/teddybear.png') no-repeat center;

          &.attention {
            width: 250px;
            height: 250px;
            margin: -25px;
          }
        }

        &#item-pyama {
          background: url('../images/pyama.png') no-repeat center;

          &.attention {
            width: 250px;
            height: 250px;
            margin: -25px;
          }
        }
      }
    }

    .navigation {
      background: url('../images/achtergrond-scene3.png');
      background-position: left bottom;
      background-size: 100% auto;
      background-repeat: no-repeat;
      bottom: 0 !important;
      height: 100%;
    }

    #woezel-pip {
      background: url('../images/woezel-pip.png');
      background-repeat: no-repeat;
      background-position: left bottom;
      width: 607px;
      height: 338px;
      position: absolute;
      bottom: 50px;
      z-index: 1;
    }

    #struiken {
      background: url('../images/struiken.png');
      background-repeat: no-repeat;
      background-position: left bottom;
      width: 789px;
      height: 618px;
      position: absolute;
      bottom: 150px;
      animation-delay: 0s !important;
    }
  }
}

#screen4 {
  #screen-container {
    .view {
      background: url('../images/scene4.png');
      background-position: left bottom;
      background-size: 100% auto;
      background-repeat: no-repeat;
    }

    .navigation {
      background: url('../images/scene4-bg.png');
      background-position: left bottom;
      background-size: 100% auto;
      background-repeat: no-repeat;
      bottom: 0 !important;
      height: 100%;
    }

    .background {
      background-color: #0c2946;
      background-image: url('../images/bg-night.png');
    }
  }
}


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: none;

  .overlay-inner {
    opacity: 0;
    transform: scale3d(0.7, 0.5, 1);
    transition: opacity 0.3s, transform 0.3s;
  }

  .overlay-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,58,123,0.4);
    opacity: 0;
    transition: opacity 0.75s;
  }

  .overlay-container {
    width: 100%;
    height: 100%;
    padding: 150px;
    position: relative;
    animation-duration: 0.75s;
    animation-name: bounceIn;
    background: url('../images/canvas.png') no-repeat center;
    background-size: contain;
  }

  button[data-toggle="close"] {
    position: absolute;
    top: 10px;
    right: 50%;
    transform: translateX(50%);
    width: 161px;
    height: 160px;
    background: url('../images/sluiten.png') no-repeat center;
    background-size: contain;
    outline: none;
    border: none;
    z-index: 1;
  }

  &.active {
    display: block;

    .overlay-inner {
      opacity: 1;
      transform: scale3d(1, 1, 1);
      transition: opacity 0.3s, transform 0.3s;
      transition-delay: 0.2s;
    }

    .overlay-container {
      animation-duration: 0.75s;
      animation-name: bounceIn;
    }

    .overlay-background {
      opacity: 1;
    }
  }
}

#story-overlay {
  //.story {
  //  width: 100px;
  //  height: 100px;
  //  background-color: white;
  //  float: left;
  //  opacity: 0;
  //  transform: scale(0);
  //
  //  @for $i from 1 through 20 {
  //    &:nth-child(#{$i}) {
  //      animation: popIn 0.3s forwards;
  //      animation-delay: ($i * .35) + 1s ;
  //    }
  //  }
  //}


  .overlay-inner {
    text-align: center;

    .title {
      margin-bottom: 30px;
      margin-top: 80px;
    }

    img {
      display: inline-block;
    }

    .story-play {
      position: relative;
      top: -120px;
      opacity: 0;
      //  transform: scale(0);
    }
  }

  &.active {
    .story-play {
      animation: popIn 0.3s forwards;
      animation-delay: 1s;
    }
  }
}

#vlinder-hover {
  position: absolute;
  height: 200px;
  width: 300px;
  bottom: 37%;
  @include cursor();
}
#vlinder {
  position: absolute;
  bottom: 37%;
  opacity: 0;
  visibility: hidden;

  .content:before {
    content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    background: url('../images/vlinder.png') 0 0 no-repeat;
  }

  &.fly {
    animation: butterfly-vertical 4s ease-in;
    opacity: 1;
    visibility: visible;

    .content {
      animation: butterfly-horizontal 4s ease-out;

      &:before {
        animation: butterfly 0.5s step-end infinite;
      }
    }
  }
}

@keyframes butterfly {
   0% { background-position: 0px 0px; }
  25% { background-position: -120px 0px; }
  50% { background-position: -240px 0px; }
  75% { background-position: -360px 0px; }
}

@keyframes butterfly-horizontal {

  25% { transform: translateX(300px); }
  50% { transform: translateX(400px); }
  65% { transform: translateX(200px); }
  75% { transform: translateX(100px); }
}
@keyframes butterfly-vertical {
  35% { transform: translateY(-250px); }
  50% { transform: translateY(-300px); }
  65% { transform: translateY(-275px); }
}

#success-overlay {


  .content {
    width: 750px;
    height: 750px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 400px;
      height: auto;
      position: relative;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0; top: 0;
      width: 100%;
      height: 100%;
      background: url(../images/badge-bg.png);
      background-size: cover;
      z-index: 0;
      // animation: rotate 60s infinite linear;
      backface-visibility: hidden;
    }
  }

  &.active {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: popIn 0.3s;
  }

  &.fadeout {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: overlayFadeout 0.25s ease forwards;
  }
}

@keyframes overlayFadeout {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes rotate {
  // 25% { transform: rotate(90deg); }
  // 50% { transform: rotate(180deg); }
  // 75% { transform: rotate(270deg); }
  100% { transform: rotate(360deg); }
}
